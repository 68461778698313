document.addEventListener("DOMContentLoaded", () => {
    const form = document.querySelector("#form")
    const formSubmit = document.querySelector("#form-submit")
    formSubmit.addEventListener("click", () => {
        Array.from(form.elements).forEach((input) =>
            input.classList.add("beta-form__field--validated")
        )
    })
    form.addEventListener("submit", (event) => {
        event.preventDefault()
        let formElements = new FormData(form)
        const endpoint = form.action

        const formData = []
        for (let [key, value] of formElements) {
            const encodedKey = encodeURIComponent(key)
            const encodedValue = encodeURIComponent(value)
            formData.push(encodedKey + "=" + encodedValue)
        }
        const formBody = formData.join("&")
        formSubmit.disabled = true
        formSubmit.classList.add("button-disabled");
        fetch(endpoint, {
                method: "POST",
                body: formBody,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
            .then((response) => response.json())
            .then(({ data: { status } }) => {
                form.reset()
                formSubmit.disabled = false
                formSubmit.classList.remove("button-disabled");
                Array.from(form.elements).forEach((input) =>
                    input.classList.remove("beta-form__field--validated")
                )

                switch (status) {
                    case "code-resending":
                    case "request-repeated":
                        {
                            document
                            .querySelector(".resubmit-modal")
                            .classList.remove("visually-hidden")
                            break
                        }
                    case "request-created":
                        {
                            document
                            .querySelector(".submit-modal")
                            .classList.remove("visually-hidden")
                            break
                        }
                    default:
                        console.error("Unsupported status returned from API.")
                }
            })
            .catch((error) => { formSubmit.disabled = false, formSubmit.classList.remove("button-disabled"), console.error(error) })
    })
})

//Skip empty fields
// function removeEmpties() {
//     var form = document.getElementById("theform");
//     var inputs = form.children;
//     var remove = [];
//     for(var i = 0; i < inputs.length; i++) {
//         if(inputs[i].value == "") {
//             remove.push(inputs[i]);
//         }
//     }

//     if(remove.length == inputs.length - 1)
//       return false;

//     for(var i = 0; i < remove.length; i++) 
//       form.removeChild(remove[i]);
//     return true;
// }